<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <SelectMultipleWarehouse
          class="mb-3"
          :multiple="false"
          :label="$t('labels.warehouse')"
          :placeholder="$t('labels.warehouse')"
          name="id_warehouses"
          @onFilter="onFilterChange"
        />
        <!-- <SelectFilter
          class="mb-3"
          :options="yesNoOptions"
          :label="$t('labels.status')"
          :placeholder="$t('labels.status')"
          name="status"
          @onFilter="onStatusChange"
        /> -->
        <SelectMultipleCustomerByWarehouse
          class="mb-3"
          :multiple="false"
          :is-active-only="filters.status"
          :id-warehouses="filters.id_warehouses"
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          :disabled="idWarehouse"
          name="id_customers"
          @onFilter="onFilterChange"
        />
        <SelectFilter
          :options="shopOptions"
          :label="$t('labels.shop')"
          :placeholder="$t('labels.shop')"
          :disabled="!customer || !customer.is_multiple_shop"
          name="id_customer_shops"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="9"
        v-if="idWarehouse && idCustomer && customer && customer.id"
      >
        <CustomerContract
          v-if="
            !customer.is_multiple_shop ||
            (customer.is_multiple_shop && idCustomerShop)
          "
          :key="`customer_contract_${idCustomer}_${idCustomerShop}`"
          :id-customer="idCustomer"
          :id-warehouse="idWarehouse"
          :id-customer-shop="idCustomerShop"
        />
        <!-- <CustomerShopContract
          v-else
          :key="`customer_shop_contract_${idCustomer}`"
          :id-customer="idCustomer"
          :id-warehouse="idWarehouse"
        /> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { YES_NO_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "Customer",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    SelectMultipleWarehouse: () =>
      import("@/components/administration/SelectMultipleWarehouse"),
    SelectMultipleCustomerByWarehouse: () =>
      import("@/components/administration/SelectMultipleCustomerByWarehouse"),
    CustomerContract: () =>
      import("@/components/delivery/CustomerContract.vue"),
    // CustomerShopContract: () => import("@/components/delivery/CustomerShopContract.vue"),
  },
  data: () => ({
    filters: {
      id_warehouses: [],
      id_customers: [],
      id_customer_shops: [],
      status: 0,
    },
    yesNoOptions: [...YES_NO_OPTIONS],
    isLoading: false,
    customer: {},
    shops: [],
  }),
  computed: {
    idWarehouse() {
      return (
        (this.filters.id_warehouses &&
          this.filters.id_warehouses.length &&
          this.filters.id_warehouses[0]) ||
        null
      );
    },
    idCustomer() {
      return (
        (this.filters.id_customers &&
          this.filters.id_customers.length &&
          this.filters.id_customers[0]) ||
        null
      );
    },
    idCustomerShop() {
      return (
        (this.filters.id_customer_shops &&
          this.filters.id_customer_shops.length &&
          this.filters.id_customer_shops[0]) ||
        null
      );
    },
    shopOptions() {
      return [...this.shops].map((shop) => ({
        value: shop.id,
        text: `${shop.name} - ${shop.code}`,
      }));
    },
  },
  watch: {
    idCustomer() {
      this.getDetailCustomer();
    },
  },
  methods: {
    onStatusChange(filter) {
      this.filters = { ...this.filters, status: filter.value };
    },
    onFilterChange(filter) {
      let values = filter.value;
      if (["string", "number"].includes(typeof filter.value)) {
        values = [filter.value];
      }
      this.filters = { ...this.filters, [filter.name]: values };
    },
    async getDetailCustomer() {
      if (!this.idCustomer) {
        this.customer = {};
        return false;
      }

      const { data } = await httpClient.post("/customer/v1/detail", {
        id: this.idCustomer,
      });

      this.customer = { ...data.customer };

      this.getCustomerShop();
    },

    async getCustomerShop() {
      if (!this.customer.is_multiple_shop) {
        this.shops = [];
        return false;
      }

      const { data } = await httpClient.post("/customer/v1/shop-list", {
        id_customer: this.customer.id,
        is_all: 1,
      });
      this.shops = [...data];
    },
  },
};
</script>

<style scoped></style>
